<template>
  <Component
     :is="require(`@/assets/img/icons/${name}.svg`)"
     v-bind="$attrs"
     @v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'iconSvg',
  inheritAttrs: false,

  props: {
    name: {
      type: String,
      required: true,
    },
  },
}
</script>
