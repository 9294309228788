<template>
    <div class="autoload"></div>
</template>

<script>

export default {
    name: "IntersectionArea",
    data() {
        return {
            observer: null,
            intesectionOptions: {
                root: null,
                rootMargin: '5px',
                threshold: 0.5,
            }
        }
    },
    methods: {
        intersectionInit() {
            // требует вызова $forceUpdate();
            if (this.observer === null) {
                const target = document.querySelector('.autoload');
                this.observer = new IntersectionObserver(this.intesectionCallback, this.intesectionOptions)
                this.observer.observe(target);
            }
        },
        intesectionCallback(entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.$emit('intersect');
                    observer.unobserve(entry.target);
                    this.observer = null;
                }
            })
        },
    },
};
</script>


<style scoped>


.autoload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 380px;
    cursor: pointer;
    transition: 0.3s all;
}


</style>
