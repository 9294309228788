import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal-box',{attrs:{"title":"Настройки чата"},on:{"close":function($event){return _vm.$emit('close')}}},[_c(VCard,{attrs:{"color":"transparent"}},[_c(VTabs,{attrs:{"color":"primary","right":""}},[_c(VTab,[_vm._v("Участники")]),_c(VTab,[_vm._v("Редактирование")]),_c(VTabItem,{key:1,staticClass:"tab__content"},[_c('user-search',{attrs:{"userCallback":_vm.addUser,"hiddenUsers":_vm.groupChat.users}}),_vm._l((_vm.groupChat.users),function(userAdded,index){return _c('div',{key:index},[_c('user-item',{attrs:{"showButton":_vm.getUser.id !== userAdded.id,"user":userAdded,"color":"error"},on:{"action":function($event){return _vm.removeUser(index)}},scopedSlots:_vm._u([{key:"button",fn:function(){return [_vm._v(" Удалить ")]},proxy:true}],null,true)})],1)})],2),_c(VTabItem,{key:2,staticClass:"tab__content"},[_c(VTextField,{attrs:{"variant":"outlined","label":"Название группы","required":""},model:{value:(_vm.groupChat.name),callback:function ($$v) {_vm.$set(_vm.groupChat, "name", $$v)},expression:"groupChat.name"}}),_c(VBtn,{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.deleteGroupChat(_vm.chatId); _vm.$emit('close'); _vm.$router.push('/')}}},[_vm._v(" Удалить группу")])],1)],1)],1),_c('div',{staticClass:"line",staticStyle:{"margin-bottom":"20px"}}),_c('div',{staticClass:"buttons"},[_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.saveGroupChat()}}},[_vm._v("Сохранить")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }