<template>


  <div class="modalbox">  

  <modal-box v-if="jwt" title="Вы авторизованы" :showClose=false>

    <form class="input_form" v-on:submit.prevent="fetchLogout()">
      <router-link to="/"><button class="link">На главную</button></router-link>
      <button ref="submit">Выход</button>
      <div class="error-msg">{{ errorMsg }}</div>
    </form>

  </modal-box>
  <modal-box v-else title="Регистрация" :showClose=false>
  
    <form class="input_form" v-on:submit.prevent="RegistrateUser(authForm)">
      <v-text-field autocomplete="new-password" variant="outlined" v-model="authForm.username" label="username" required> </v-text-field>
      <v-text-field autocomplete="new-password" variant="outlined" v-model="authForm.password" label="password" type="password" required> </v-text-field>
      <v-text-field autocomplete="new-password" variant="outlined" v-model="authForm.re_password" label="password" type="password" required> </v-text-field>
      <v-btn color="primary" type="submit">Подтвердить</v-btn>
      <v-btn href="/login/">У меня есть учетная запись</v-btn>
      <div v-for="(err, index) in errorMsg" :key=index class="error">
        {{ err }}
      </div>
    </form>

  </modal-box>
    

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalBox from '../components/uikit/ModalBox.vue';
export default {
  name: "LoginPage",
  data() {
    return {
      authForm: {
        username: "",
        password: "",
      },
    };
  },
  props: {},
  components: {
    ModalBox,
  },
  computed: {
    ...mapGetters(["jwt", "errorMsg"]),
  },
  methods: {
    ...mapActions(["RegistrateUser", ]),
  },
  mounted() {
  },
};
</script>



