<template>
    <div>
      <v-btn text color="white" @click="$emit('click');">
        <slot> </slot>
      </v-btn>
    </div>
</template>
  
  <script>
  
  export default {
    name: "SideBarButton",
    components: {
    },
  };
  </script>
  
  
  <style scoped>
  
  /* .sidebar-button {
    margin: 0;
    height: 50px;
    width: 90px;
    border: 0px;
    width: 100%;
    margin-bottom: 20px;
} */

  </style>
  