<template>
    <div 
    
    @click.right="openContext"
    @contextmenu.prevent
    :class="isSelf ? 'text__self': 'text'">
        <div class="author"> {{author}} </div>
        <div class="fragment" v-for="(msg, index) in text.trim().split('\n')" :key="index">
            {{ msg }}
        </div>
        <date-time :timestamp="date || ''"> </date-time>
    </div>
</template>

<script>
import DateTime from './DateTime.vue';
export default {
    name: "MessageText",
    data() {
    return {
        showMessageMenu: false,
    }
  },

    methods: {
        openContext() {
                  this.$emit('onOpenContext')
    
        }
    },
    components: {
        DateTime
    },
    computed: { },
    props: {
        text: String,
        date: String,
        author: String,
        isSelf: Boolean,
    },
};
</script>


<style scoped>

.author {
    font-weight: 600;
    color: green;
    font-size: 14px;
}

.text, .text__self {
    background-color: white;
    height: max-content;
    padding: 10px;
    width: max-content;
    max-width: 40vw;
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 0;
}

.text {
    border-radius: 8px 8px 8px 0px;
} 

.text__self {
    border-radius: 8px 8px 0px 8px;
}

.text__self {
    background-color: #d8ffd6;
}

.text::before, .text__self::before {
  content: "";
  position: absolute;
  background-color: transparent;
  height: 50px;
  bottom: 0;
  width: 40px;
  z-index: -1;
}


.text::before {
    left: -40px;
    width: 40px;
    border-bottom-right-radius: 50%;
    box-shadow: 20px 0px 0 0 #ffffff;
}

.text__self::before {
  right: -40px;
  width: 40px;
  border-bottom-left-radius: 50%;
  box-shadow: -20px 0px 0 0 #d8ffd6;
  
}

.fragment {
    overflow-wrap: break-word;
}

.time {
    color: grey;
    margin-block-start: 0em;
    margin-block-end: 0em;
}

p {
    font-size: 14px;
    margin-block-start: 0.4em;
    margin-block-end: 0.4em;
    word-break: break-all;
    white-space: normal;
    overflow-wrap: anywhere;
    overflow-wrap: normal;
    overflow: hidden;
}
.message_menu {
    position: absolute;
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    top:100%;
    z-index: 1;
     
}

</style>
