<template>
    <div>
        <MessageItem 
          v-for="message in messages" :key="message.id"
          :message="message" 
          :chatId="chatId" 
        />
    </div>

</template>

<script>
import MessageItem from '@/components/MessageItem.vue'

export default {
  name: "MessageList",
  data() {
      return {
    }
  },
  props: {
    messages: Array,
    chatId: Number,
  },
  components: {
    MessageItem,
  },

};
</script>

