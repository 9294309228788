export default ({
    state: {
        ws: undefined,
    },
    getters: {
        getWs: (state) => state.ws,
    },

    mutations: {
        setWs(state, ws) {
            state.ws = ws;
        },
        delWs(state) {
            state.ws = undefined;
        },
        setStatus(state, status) {
            if (state.ws) {
                state.ws.status = status;
                console.log(status);
            }
        },
    },

    actions: {
        websocketConnect(ctx) {
            const ws = new WebSocket(`${process.env.VUE_APP_BACKEND_WSS}/stream/`);
            console.log(process.env.VUE_APP_BACKEND_WSS);
            ctx.commit('setWs', ws);
            ws.onopen = () => {
                ctx.dispatch('onWebsocketOpen', ws);
            };
        },
        websocketDisconnect(ctx) {
            const ws = ctx.getters.getWs;
            ws.close();
            console.log(ws);
            this.status = "disconnected";
            ctx.commit('setStatus', 'disconnected');
        },
        onWebsocketMessage(ctx, data) {
            data = JSON.parse(data);
            ctx.dispatch("directMessage", data);
        },
        onWebsocketOpen(ctx, ws) {
            ctx.commit('setStatus', 'connected');
            ws.onmessage = ({ data }) => {
                ctx.dispatch('onWebsocketMessage', data);
            };
        },
        websocketSend(ctx, data) {
            let ws = ctx.getters.getWs;
            let message = JSON.stringify(data);
            console.log(message);
            ws.send(message);
        },
    }
})