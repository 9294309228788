<template>
  <div class="chat_info">
    <div class="chat_name">
      {{ chat.name }}
    </div>
    <div v-if="chat.last_msg" class="chat_message">
      <b> {{ chat.last_msg.author.username }}: </b>
      {{ chat.last_msg.text }}
    </div>
  </div>
</template>


<script>
export default {
  name: "ChatInfo",
  props: {
    chat: {type: Object},
  },

};
</script>


<style scoped>

.chat_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  overflow: hidden;
  height: 100%;
  padding: 10px;
  width: calc(40vw - 130px);
  color: white;
}

.chat_name {
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.chat_message {
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

@media (max-width: 576px) {
  .chat_info {
    width: calc(100vw - 130px);
  }
}
</style>

