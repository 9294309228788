<template>
    <div>

        <form v-on:submit.prevent="findUser" class="input_form">
        <div class="search-input">
                <v-text-field variant="outlined"
                    v-model="searchString"
                    label="Username"
                    required
                    :rules="rules.username"
                ></v-text-field>
                <v-btn color="primary" type="submit"> Найти </v-btn>
            </div>
        </form>


        <ul v-if="searchResult && searchResult.length" class="search-result">
            <user-item 
                v-for="user in filteredSearchResult" 
                :key=user.id 
                :user="user"
                :hideAction="user.has_peer"
                @action="userCallback({ ...user, ...callbackArgs }); $emit('close')"
            >
                <template #button> Добавить </template>
            </user-item>
        </ul>
        <ul v-else-if="searchResult === undefined" class="search-result">
            <li class="search-result__item no_result">
                <span> Ничего не найдено </span>
            </li>
        </ul>
        <ul v-else class="search-result">
            <li class="search-result__item no_result">
                <span> Ничего не найдено</span>
            </li>
        </ul>
    </div>
</template>


<script>
import axios from 'axios';
import UserItem from '../uikit/UserItem.vue';

export default {
    name: "UserSearch",
    data() {
        return {
            searchResult: undefined,
            searchString: '',
            rules: {
                username: [v => !!v || 'Введите значение'],
            }
        }
    },
    components: {
        UserItem,
    },
    computed: {

        filteredSearchResult() {
            if (!this.searchResult) {
                return this.searchResult
            }
            return this.searchResult.filter(
                user => this.hiddenUsers.findIndex(i => i.id == user.id) < 0
            );
        }
    },
    props: {
        userCallback: {
            type: Function,
        },
        callbackArgs: {
            type: Object,
            default: () => { },
            required: false,
        },
        hiddenUsers: {
            type: Array,
            default: () => [],
        }
    },
    methods: {
        findUser() {
            if (this.searchString === '') {
                this.searchResult = undefined;
                return;
            }
            axios.get(`/api/auth/user-search/?search=${this.searchString}`)
                .then((response) => {
                    this.searchResult = response.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>


<style scoped>
.search-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: 10px;
}

.search-result {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
}






.no_result {
    display: flex;
    justify-content: center;
    height: 50px;
}

.user-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.search-result__item>div>button {
    height: 35px;
}
</style>
