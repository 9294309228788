<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

import { mapGetters, mapActions } from "vuex";

export default {
  name: 'App',
  methods: {
    ...mapActions(["fetchUser"]),
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  created: function () {
    if (!(this.getUser && this.getUser.id)) {
      this.fetchUser();
    }
  },
}
</script>


<style>

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  background-color: #343c4b;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
}

a {
  width: 100%;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    border-radius: 30px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 30px;
    margin: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0);
    border: 4px solid rgb(145, 145, 145);
    border-radius: 30px;
}


.error-msg {
  color: red;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}



/* input {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 2px solid rgb(0, 0, 0);
    padding: 5px 20px;
} */

/* button {
    color: rgb(0, 0, 0);
    background-color: white;
    font-weight: bold;
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 2px solid rgb(0, 0, 0);
    transition: 0.2s;
    cursor: pointer;
} */

.input_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap:20px;
    width: 100%;
    margin: 0 auto;
}

/* button:hover {
    color: rgb(255, 255, 255);
    background: rgb(32, 51, 108);
    border: 2px solid rgb(40, 64, 135);
} */



.click_outside{
    position: fixed;
    top:0;
    left: 0;
    z-index: 100;
    width: 100vw;
    height: 100dvh;
    background-color: rgba(0, 0, 0, 0.536); 
}

</style>
