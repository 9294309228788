<template>
    <div>
        <side-bar-button @click="showModal"> 
            <slot> </slot>
         </side-bar-button>
        <modal-box v-if="isShowModal" @close="hideModal" title="Поиск контактов" :isBurger="true">
            <UserSearch @close="hideModal" :userCallback="createDirectChat"/>
        </modal-box>
    </div>
</template>


<script>
import UserSearch from "./UserSearch.vue";
import ModalBox from "../uikit/ModalBox.vue";
import { mapActions } from "vuex";
import SideBarButton from '../sidebar/SideBarButton.vue';
export default {
    name: "UserSearchMenu",
    data() {
        return {
            isShowModal: false,
            username: '',
            searchResult: [],
        };
    },
    components: {
        UserSearch,
        ModalBox,
        SideBarButton,
    },
    computed: {},
    methods: {
        ...mapActions(["createDirectChat"]),
        showModal() {
            this.isShowModal = true;
        },
        hideModal() {
            this.isShowModal = false;
        },
    },
};
</script>


<style scoped>




</style>
