import axios from 'axios';
export default ({
    state: {
        messages: {},
    },

    getters: {
        getMessages: (state) => state.messages,

    },

    mutations: {
        setMessages(state, data) {
            let key = `${data.chat}`;
            let messages = {...state.messages };
            if (!messages[key]) {
                 messages[key] = [] 
            }
            messages[key] = [...data.messages.reverse(), ...messages[key]];
            state.messages = messages;
        },

        clearMessages(state) {
            state.messages = {};
        },
        deleteMessage(state, data) {
            let messages = state.messages[data.chat] || [];
            let index = messages.findIndex((item) => Number(item.id) === Number(data.id))
            messages.splice(index, 1);
            state.messages[data.chatId] = messages;
        },
        pushMessage(state, data) {
            let key = `${data.chat}`;
            if (state.messages && state.messages[key]) {
                state.messages[key].push(data);
            } else {
                state.messages[key] = [];
                state.messages[key].push(data);
            }
        },

    },

    actions: {

        directMessage(ctx, data) {
            if (data.msg_type === 'msg_del') {
                ctx.commit('deleteMessage', data);
            }
            if (data.msg_type === 'msg_new') {
                ctx.commit('pushMessage', data);
                ctx.commit('setNewMessage', data);
            }
            if (data.msg_type === 'chat_leave') {
                alert(`${data.author.username} покинула чат чат`);
            }
            if (data.msg_type === 'chat_join') {
                alert(`${data.author.username} зашел в чат чат чат`);
            }
            if (data.msg_type === 'chat_create') {
                alert('создание чата');
            }

        },
        fetchRoomMessages(ctx, data) {
            if (!this.getters.getMessages[`${data.chat}`]) {
                let params = {
                    chat: data.chat,
                    limit: 10,
                }
                axios.get('/api/chat/message/', { params })
                    .then((response) => {
                        ctx.commit('setMessages', { messages: response.data, chat: data.chat });
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        fetchRoomMessagePage(ctx, params) {
            axios.get('/api/chat/message/', { params })
                .then((response) => {
                    ctx.commit('setMessages', { messages: response.data, chat: params.chat });
                })
                .catch((err) => {
                    console.log(err);
                });
            },


    },

})