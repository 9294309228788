<template>
  <div class="main">
    <SideBar 
      :class="!isMain ? 'mobile_hide' : 'sidebar'" 
      @disconnect="websocketDisconnect" 
      @setRoom="setRoom"
    />
    <Chat 
      v-if="$route.params.id" 
      :class="isMain ? 'mobile_hide' : 'chat'"
    />

  </div>
</template>


<script>

import SideBar from '@/components/sidebar/SideBar.vue'
import { mapGetters, mapActions } from "vuex";
import Chat from './Chat.vue'
export default {
  name: "Dashboard",
  data() {
    return {
      chatId: null,
    };
  },
  components: {
    SideBar,
    Chat,
  },
  computed: {
    isMain() {
      if (this.$route.params?.id) {
        return false;
      }
      return true;
    },
    ...mapGetters([
    ]),
  },
  methods: {
    ...mapActions(["websocketConnect", "websocketDisconnect"]),
    setRoom(chatId) {
      if (chatId !== Number(this.$route.params.id)) {
        this.$router.push(`/chat/${chatId}`);
      }
    },
  },
  created() {
    this.websocketConnect();
  },
  beforeDestroy() {
    this.websocketDisconnect();
  },
};

</script>


<style scoped>
.main {
  display: flex;
  flex-direction: row;
  height: 100dvh;
}


.sidebar {
  background-color: #343c4b;
  color: #e1ebff;
  width: 40vw;
}

.chat {
  background: linear-gradient(335deg, #f2f2f2 0%, #c5c7cb 50%, #686868 100%);
  color: #343c4b;
  width: 60vw;
}


@media (min-width: 768px) {}

@media (max-width: 576px) {

  .sidebar {
    height: 100dvh;
    width: 100vw;
  }

  .mobile_hide {
    display: none;
  }

  .chat {
    width: 100vw;
  }
}
</style>
