<template>
    <div>
      <ChatHeader 
        @showSideBar="$emit('showSideBar')" 
        @openGroupInfo="isShowGroupInfo=true" 
        @openUserInfo="isShowUserInfo=true" 
      />

      <MessageArea :chatId="chatId" />
      <MessageForm :chatId="chatId" />
      <ChatUserSearch 
      v-if="getChatCurrent && getChatCurrent.id && isShowGroupInfo"
        :chatId="chatId" 
        :chat="getChatCurrent"
        @close="isShowGroupInfo=false"
      />

      <ChatUserInfo :user="getChatCurrent" 
        v-if="getChatCurrent && getChatCurrent.id && isShowUserInfo"
        :chatUsers="getChatCurrent.users"
        :chatId="chatId" 
        @close="isShowUserInfo=false"
      />

    </div>
</template>


<script>

import ChatHeader from '@/components/ChatHeader.vue'
import MessageArea from '@/components/MessageArea.vue'
import MessageForm from '@/components/MessageForm.vue'
import ChatUserSearch from '@/components/ChatUserSearch.vue'  
import ChatUserInfo from '@/components/ChatUserInfo.vue'  
import { mapGetters} from "vuex";
export default {
  name: "Chat",
  data() {
    return {
      isShowGroupInfo: false,
      isShowUserInfo: false,
    };
  },
    computed: {
    chatId() {
        if (this.$route.params.id)
            return Number(this.$route.params.id);
        return null;
    },
    ...mapGetters(["getChatCurrent"]),
    },
  components: {
    ChatHeader,
    MessageArea,
    MessageForm,
    ChatUserSearch,
    ChatUserInfo,
  },
};

</script>


