import axios from 'axios';
import router from '@/router';
export default ({
    state: {
        jwt: localStorage.getItem('jwt') || '',
        socialLink: '',
        errorMsg: '',
    },
    getters: {
        isLoggedIn: (state) => !!state.jwt,
        jwt: (state) => state.jwt,
        errorMsg: (state) => state.errorMsg,
        Authorization(state) {
            let jwt = state.jwt;
            return (jwt) ? `JWT ${JSON.parse(jwt).access}` : '';
        },
    },
    mutations: {
        setErrorMsg(state, errorMsg) {
            state.errorMsg = errorMsg;
        },

        setToken(state, jwt) {
            state.jwt = JSON.stringify(jwt);
            localStorage.setItem('jwt', JSON.stringify(jwt));
            state.errorMsg = '';
        },

        removeToken(state) {
            localStorage.setItem('jwt', '');
            state.jwt = '';
        },

        updateToken(state, token) {
            if (state.jwt !== '') {
                state.jwt.access = token;
                localStorage.setItem('jwt', JSON.stringify(state.jwt));
            }
        },

    },
    actions: {
        fetchLogin(ctx, data) {
            axios.post(`/api/auth/jwt/create/`, data, {})
                .then((response) => {
                    ctx.commit('setToken', response.data);                 
                    router.push(`/`);
                })
                .catch((err) => {
                    ctx.commit('setErrorMsg', err.response.data.detail);
                });
        },

        RegistrateUser(ctx, data) {
            axios.post(`/api/auth/users/`, data, {})
                .then((response) => {
                    if (response.status === 201) {
                        let url = new URL(window.location.href);
                        let next = url.searchParams.get("next");
                        router.push({path:`/login/?${next}`});
                    }
                })
                .catch((err) => {
                    let data = err.response.data;
                    let error_list = [];
                    if (data.detail) {
                        error_list.push(err.response.data.detail);
                    }
                    if (data.password) {
                        data.password.forEach(e => error_list.push(e))
                    }
                    if (data.username) {
                        data.username.forEach(e => error_list.push(e))
                    }
                    ctx.commit('setErrorMsg', error_list);
                });
        },
        fetchLogout401(ctx) {
            ctx.commit('clearChat');
            ctx.commit('removeToken');        
        },

        fetchLogout(ctx) {
            axios.post(`/api/auth/logout/`)
                .then((r) => {
                    if (r.status === 200) {
                        ctx.dispatch('fetchLogout401');
                        if (`login/` !== window.location.pathname) {
                            router.push(`/login/`);
                        }
                    }
                })
                .catch((err) => {
                   console.log(err); 
                });
            },
            
            jwtUpdate(ctx, token) {
            ctx.commit('updateToken', token);
        },

    },
})