<template>
    <div class="fixed-overlay">
        <div @click="$emit('close')" class="click_close_area"></div>
        <div :style="modalBoxStyle" class="modalbox">
            
            <div class="header">
                <h4> {{ title }} </h4>
                <div v-if="showClose" @click="$emit('close')" class="close">
                    <iconSvg name="iconclose" />
                </div>
            </div>
            <div class="modalbox_wrap">
                <slot></slot>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: "ModalBox",
    props: {
        showClose: {
            type: Boolean,
            default: true,
        },
        isBurger:
        {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        width: {
            type: String,
        },
        height: {
            type: String,
        }
    },
    data() {
        return {};
    },
    watch: {},
    components: {},
    computed: {
        modalBoxStyle() {
            let obj = {}
            if (this.width)
                obj['max-width'] = this.width;
            if (this.height)
                obj['max-height'] = this.height;
                console.log(obj);
            return obj
        }
    },
    methods: {
        escClose(e) {
            if ((e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27)) {
                this.$emit('close');
                document.removeEventListener('keydown', this.escClose, true);
            }
        }
    },
    mounted() {
        document.addEventListener('keydown', this.escClose, true);
    },
    updated() { },
};
</script>


<style scoped>

.modalbox {
    position: absolute;
    width: 700px;
    height: 600px;
    left: 50%;
    top: 50%;
    margin-left: -350px;
    margin-top: -300px;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
    z-index: 2000;
    border-radius: 8px;
    overflow: hidden;
    background-color: #344e82;
    color: #ffffff;
}

.modalbox_wrap {
    background-color: #e8f0fe;
    color: #000;
    width: 100%;
    height: calc(100% - 60px);
    overflow: scroll;
    display: flex;
    color: black;
    flex-direction: column;
    padding: 32px;
}

.click_close_area {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1700;
}

.fixed-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    z-index: 1500;
    overflow: hidden;
}


.header {
    height: 60px;
    text-align: center;
    position: sticky;
    top:0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}


.close {
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 15px;
    top: 0px;
    right: 0px;
    padding: 16px;
    cursor: pointer;
    transition: 0.1s;
    z-index: 200;
}

.close:hover {
    transform: scale(1.1);
}

@media (max-width: 768px) { 
   .modalbox {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top:0;
    left:0;
    border-radius: 0;
  }
}

 @media (max-width: 576px) { 
    .modalbox_wrap {
        padding: 15px;
    }
   .modalbox {
    width: 100%;
    height: 100%;
    position: fixed;
    margin: 0 auto;
    top:0;
    left:0;
    border-radius: 0;
}

 }

</style>
