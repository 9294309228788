<template>
  <div class="message__list resize-m-list" id="message__list">
    <slot></slot>
    <div class="go_down" @click="goDown">
      <iconSvg name="arrow_up" />
    </div>
  </div>
</template>

<script>

export default {
  name: "MessageScroll",
  data() {
    return {
      container: null,
      firstLoad: null,
      last_message_height: 0,
    }
  },

  methods: {

    goDown() {
      this.container = document.getElementById("message__list");
      this.container.scrollTo({ top: this.container.scrollHeight, behavior: 'smooth' });
    },
    trackScroll(e) {
      const threshhold = 200;
      let element = e.target;
      let button = document.querySelector('.go_down');
      let scrollBottom = element.scrollTop + element.getBoundingClientRect().height;

      if (Math.abs(scrollBottom - element.scrollHeight) < threshhold) {
        button.style.visibility = "hidden";
        button.style.opacity = '0';
        button.style['pointer-events'] = 'none';
      }
      else {
        button.style.visibility = "visible";
        button.style.opacity = '1';
        button.style['pointer-events'] = 'auto';
      }

    },
  },

  mounted() {
    let element = document.querySelector('.message__list');
    element.addEventListener('scroll', this.trackScroll);
  },

  updated() {

  },

};
</script>


<style scoped lang="scss">
@import '@/styles/_variable.scss';


.message__list {
  height: calc(100dvh - #{$header_h} - #{$msg_input_h} - #{$message_list_mg});
  max-height: calc(100dvh - #{$header_h} - #{$msg_input_h} - #{$message_list_mg});
  overflow-y: auto;
  padding: 0 30px;
}


.go_down {
  position: absolute;
  color: #fff;
  background-color: #343c4b;
  width: 70px;
  height: 70px;
  padding: 15px;
  border-radius: 50%;
  bottom: 100px;
  right: 50px;
  float: right;
  cursor: pointer;
  transition: 0.5s;
  border: 1px solid #343c4b;
  transform: rotate(180deg);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 768px) {
  .go_down {
    width: 55px;
    height: 55px;
    right: 25px;
    right: 25px;
    bottom: 75px;
  }

}

.go_down:hover {
  color: #343c4b;
  background-color: #fff;
}
</style>
