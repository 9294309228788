import Vue from 'vue'
import axios from 'axios';
export default ({
    state: {
        chat: {},
        chatList: [],
        chatDetail: {},
        chatCurrent: {},
    },
    getters: {
        getChatList: (state) => state.chatList,
        getChatDetail: (state) => state.chatDetail,
        getChatCurrent: (state) => state.chatCurrent,
    },

    mutations: {
        setChatList(state, data) {
            state.chatList = data;
        },
        setChatDetail(state, data) {
            state.chatDetail = data;
        },
        clearChat(state) {
            state.chat = {};
        },
        clearChatList(state) {
            state.chat = {};
        },
        setChatCurrent(state, data) {
            state.chatCurrent = data;
        },
        setNewMessage(state, message) {
            let chatList = state.chatList
            for (let i = 0; i < state.chatList.length; i++) {
                if (chatList[i].id === message.chat) {
                    chatList[i].last_msg = message;
                    Vue.set(state.chatList, i, chatList[i]);
                }
            }
        }
    },

    actions: {
        fetchChatList(ctx) {
            axios.get(`/api/chat/room/`)
                .then((response) => {
                    ctx.commit('setChatList', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },



        fetchCurrentChat(ctx, id) {
            axios.get(`/api/chat/room/${id}/`)
                .then((response) => {
                    ctx.commit('setChatCurrent', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        createChat(ctx, data) {
            axios.post(`/api/chat/groupchat/`, data)
                .then((response) => {
                    if (response.status < 400) {
                        ctx.dispatch("fetchChatList");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        createDirectChat(ctx, user) {
            const body = {to_user: user.id}
            axios.post(`/api/chat/directchat/`, body)
                .then(() => {
                    ctx.dispatch("fetchChatList");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updateGroupChat(ctx, {id, data}) {
            axios.patch(`/api/chat/groupchat/${id}/`, data)
                .then((response) => {
                    console.log(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteDirectChat(ctx, id) {
            axios.delete(`/api/chat/directchat/${id}/`)
                .then(() => {
                    ctx.dispatch("fetchChatList");
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        deleteGroupChat(ctx, id) {
            axios.delete(`/api/chat/groupchat/${id}/`)
                .then(() => {
                    ctx.dispatch("fetchChatList");
                })
                .catch((err) => {
                    console.log(err);
                });
        },

    },
})