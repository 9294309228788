<template>
    <div>
        <side-bar-button @click="showModal"> 
            <slot> </slot>
        </side-bar-button>
        <modal-box 
            v-if="isShowModal" 
            :isBurger="true" 
            title="Создание группы"
            @close="closeModal">
            <form v-on:submit.prevent="createNewRoom()" class="input_form">
                <v-text-field variant="outlined"
                    v-model="chatname"
                    label="Название группы..."
                    required
                ></v-text-field>
                <v-btn type="submit"> Создать </v-btn>
            </form>
        </modal-box>
    </div>
</template>


<script>
import { mapActions } from "vuex";
import ModalBox from "../uikit/ModalBox.vue";
import SideBarButton from './SideBarButton.vue';
export default {
    name: "GroupChatMenu",
    props: {
    },
    data() {
        return {
            chatname: '',
            isShowModal: false,
        };
    },
    watch: {},
    components: {
        SideBarButton,
        ModalBox,
    },
    computed: {},
    methods: {
        ...mapActions(["createChat"]),
        showModal() {
            this.isShowModal = true;
        },
        hideModal() {
            this.isShowModal = false;
        },
        createNewRoom() {
            if (this.chatname) {
                this.createChat({name: this.chatname});
                this.closeModal();
            }
        },
        closeModal() {
            this.hideModal();
            this.$emit('hideBurger');
            this.chatname = '';
        },
    },
    mounted() {
        this.chatname = '';
    },
    updated() {
        let input = this.$refs["input"];
        if (input) {
            input.focus();
        }
    },
};
</script>


<style scoped>

</style>
