<template>
  <form class="message__form" @keyup.enter="sendMessageEnter" v-on:submit.prevent="sendMessage">
    
    <textarea
        v-model="form.message"
        class="textarea resize-m-form"
        type="text"
        id='textarea'
        placeholder="Введите сообщение"/>
        <button class="submit_button" type="submit">
          <iconSvg name="send-icon"/> 
        </button>
        
    </form>
    
</template>

<script>
import { mapActions} from "vuex";
import styles from '@/styles/_variable.scss';
export default {
name: "MessageForm",
data() {
  return {
    form : {
      message : ''
    },
  };
},
props: {
    chatId: Number,
},
mounted() {
  this.autoResize();
},
methods: {
  ...mapActions(["websocketSend"]),
  autoResize() {
    let textarea = document.querySelector(".resize-m-form");
    let messages = document.querySelector(".resize-m-list");
    let self = this;
    textarea.onkeyup = function() {
      self.fitToContent(textarea, messages)
    };
  },

  fitToContent(textarea=null, messages=null) {
    if (!textarea) 
      textarea = document.querySelector(".resize-m-form");
    
    if (!messages) 
      messages = document.querySelector(".resize-m-list");
    
    textarea.style.height = "auto";
          textarea.style.height = textarea.scrollHeight + "px";
          messages.style.height = `calc(${100}vh - ${textarea.scrollHeight}px - ${styles.msg_input_h}px)`;
          let top = messages.scrollHeight;
          messages.scrollTo({top, behavior:'auto'});
  },
  
  async clearForm() {
      this.form.message = "";
  },

  sendMessageEnter(event) {
    if((event && event.shiftKey) 
      || screen.width <= 820) { return }
      this.sendMessage();
  },

  async sendMessage() {
    const message = this.form.message.trim();
    if (message) {
      this.websocketSend({command:'send_chat', chat: this.chatId, message});
      await this.clearForm();
      this.fitToContent();
    }
  },

},
};
</script>


<style scoped lang="scss">
@import '@/styles/_variable.scss';
.submit_button {
  border: 0;
}
.submit_button:hover {
  background-color: transparent;
}
.message__form {
position: fixed;
bottom: 0;
display: flex;
flex-direction: row;
justify-content: space-between;
background-color: #ffffff;
color: #343c4b;
min-height: $msg_input_h;
max-height: 100dvh;
width: 100%;
align-items: flex-end;
border-top: 1px solid grey;
}

textarea,
textarea:active,
textarea:hover,
textarea:focus {
font-family: Arial, Helvetica, sans-serif;
outline: 0;
outline-offset: 0;
position: relative;
border: none;
resize: none;
display: block;
overflow: hidden;
min-height: $msg_input_h;
line-height: 20px;
flex: 10 1 60vw;
font-size: 16px;
padding: 10px;
max-height: 70vh;
overflow: auto;
}

textarea::-webkit-scrollbar {
  width: 0px;
}

.message__form > .submit_button {
height: $msg_input_h;
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}

.message__form > .submit_button > button {
border: none;
background-color: #ffffff00;
transition: 0.2s ease;
margin: 0 auto;
}

.message__form > button:active {
zoom: 0.9;
}

button {
width: 70px;
min-width: 70px;
}


@media (max-width: 576px) { 

.message__form {
  width: 100vw;
}
}
</style>
