import VueRouter from "vue-router";
import Dashboard from '../views/Dashboard.vue';
import LoginPage from '../views/LoginPage.vue';
import SignPage from '../views/SignPage.vue';
import Chat from '../views/Chat.vue';
import store from '@/store';


let router = new VueRouter({
        mode: 'history',
        routes: [
            {
                path: '/',
                name: 'Dashboard',
                component: Dashboard,
                meta: { requiresAuth: true },
                children: [
                    {
                      path: "chat/:id",
                      component: Chat,
                      meta: { requiresAuth: true },
                    },
                  ],
            },
            {
                path: '/login/',
                name: 'LoginPage',
                component: LoginPage,
            },
            {
                path: '/registration/',
                name: 'SignPage',
                component: SignPage,
            },

        ]
    })
    // setup router
export default router

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        }
        next('/login/');
    } else {
        next();
    }
});
