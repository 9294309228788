<template>
    <message-scroll ref="messageScroll">
      <IntersectionArea
        ref="intersectionArea"
        v-if="showLoading"
        @intersect="addMessagePage"
      />
      <MessageList
      ref="messages" 
      :chatId="chatId"
      :messages="roomMessages"
      />
    </message-scroll>
</template>

<script>
import { mapGetters, mapActions} from "vuex";
import IntersectionArea from './layouts/IntersectionArea.vue';
import MessageScroll from './layouts/MessageScroll.vue';
import MessageList from './MessageList.vue';

export default {
name: "MessageArea",
  data() {
    return {
      lastMessageHeight: 0,
    }
  },
  props: {
    chatId: Number,
  },
  components: {
    IntersectionArea,
    MessageList,
    MessageScroll,
  },

  methods: {
    ...mapActions(["fetchRoomMessagePage"]),
    addMessagePage() {
      
      let maxId = this.roomMessages && this.roomMessages.length ? this.roomMessages[0].id : null 
      if (maxId && this.chatId) {
        this.fetchRoomMessagePage({chat: this.chatId, max_id: maxId, limit:10});
        this.maxId = maxId;
      }
    },
  },
  updated() {
    const scroll = this.$refs.messageScroll.$el;
    const msg = this.$refs.messages.$el;

    scroll.scrollTop = scroll.scrollTop + msg.offsetHeight - this.lastMessageHeight;
    this.lastMessageHeight = msg.offsetHeight;
    if (this.$refs.intersectionArea) {
      this.$refs.intersectionArea.intersectionInit();
    }
  },
  computed: {
    ...mapGetters(["getMessages", 'getChatCurrent']),
    showLoading() {
      let maxId = this.roomMessages && this.roomMessages.length ? this.roomMessages[0].id : null 
      return maxId !== this.maxId;
    },
    roomMessages() {
      let key = `${this.chatId}`;
      if (this.getMessages[key]) 
        return this.getMessages[key];
      return [];
      
    },
  },
};
</script>
