<template>
    <div>
        <side-bar-button @click="showModal"> 
            <slot> </slot>
        </side-bar-button>
        <modal-box 
            
            v-if="isShowModal" 
            :isBurger="true" 
            title="Настройки профиля"
            @close="hideModal"
            >   
            <form enctype="multipart/form-data" v-on:submit.prevent="saveUser" class="input_form">
                <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Pick an avatar"
                    prepend-icon="mdi-camera"
                    label="Avatar"
                    @change="fileSelected"
                ></v-file-input>
                <v-text-field variant="outlined"
                    v-model="user.first_name"
                    label="Имя"
                    required
                ></v-text-field>
                <v-text-field variant="outlined"
                    v-model="user.last_name"
                    label="Фамилия"
                    required
                ></v-text-field>
                <v-btn color="primary" type="submit" >Сохранить</v-btn>
            </form>
        </modal-box>
    </div>
</template>


<script>

    import { mapGetters, mapActions } from "vuex";
    import ModalBox from "../uikit/ModalBox.vue";
    import SideBarButton from './SideBarButton.vue';
    export default {
        name: "UserProfile",
        props: {
        },
        data() {
            return {
                isShowModal: false,
                url: null,
                user: {
                    last_name: '',
                    first_name: '',
                    logo: null,
                }
            };
        },
        watch: {},
        components: {
            SideBarButton,
            ModalBox,
        },
        computed: {
            ...mapGetters(['getUser']),
        },
        methods: {
            ...mapActions(['saveUserProfile']),
            fileSelected(file) {
                if (file) {
                    this.user.logo = file;
                    this.url = URL.createObjectURL(this.user.logo);
                } else {
                    this.url = null;
                }
            },
            saveUser() {
                this.saveUserProfile(this.user);
                this.hideModal()
            },
            showModal() {
                this.isShowModal = true;
            },
            hideModal() {
                this.isShowModal = false;
                this.$emit('hideBurger');
            },
        },
        updated() {  },
    };
</script>


<style scoped>



.image_loader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap:15px;
    cursor: pointer;
}
.image_loader:hover{
    color: blue;
}

label {
    margin-left: 25px;
}


#upload-photo {
   visibility: hidden;
   position: absolute;
   z-index: -1;
   width: 0;
}

.save {
    margin-top: 15px;
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 50px;
}
</style>
