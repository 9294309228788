<template>
    <div class="chat_avatar" :class="variant" :style="chatAvararStyle(text)">
      <img class="chat_avatar_img" v-if="hasImage && image" :src="fullImage" @error="onerrorImg" alt="">
      <span v-else class="chat_avatar_letters"> {{ chatLetter(text) }} </span>
    </div>
  </template>
  
  
  <script>

  export default {
    name: "ChatLogo",
    data() {
      return {
        hasImage: true,
        colors: [
          '#4A5FD9',
          '#3E498C',
          '#0DA633',
          '#262626',
          '#D9325E',
          '#F24B88',
          '#BF5E3B',
          '#067302',
          '#034001',
          '#8C3B4A',
        ]
      };
    },
    props: {
      variant: {
        type: String,
      },
      image: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: '',
      }
    },
    computed: {
      fullImage() {
        if (this.image) {
        if (this.image.startsWith('http')) {
          return this.image;
        }
        else {
          return `${process.env.VUE_APP_BACKEND_HOST}${this.image}`
        }
      }
      return null;
      }
    },
    methods: {
      onerrorImg() {
        this.hasImage = false;
      },
      chatAvararStyle(text) {
        if (!text || text.length < 2) {
          text = 'default';
        }
        let total =  text.charCodeAt(1) + text.charCodeAt(2);
        return {
          'background-color': `${this.colors[total % 10]}`
        };
      },
      chatLetter(text) {
        let letters = text.split(" ")
        if (letters.length === 1) {
          return text[0];
        }
        else if (letters.length >= 2) {
          return letters[0][0] + letters[letters.length-1][0];
        }
      },
    },
  };
  </script>
  
  
  <style scoped>
  
  
  .chat_avatar {
    width: 50px;
    height: 50px;
    min-width: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .small.chat_avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
  
  .chat_avatar_letters{
    color: white;
    text-transform: uppercase;
    font-size: 18px;
  }


  .small.chat_avatar {
    width: 32px;
    height: 32px;
    min-width: 32px;
  }
  

  .small .chat_avatar_letters {
    font-size: 10px;
  }

  .big.chat_avatar {
    width: 150px;
    height: 150px;
    min-width: 150px;
  }
  

  .big .chat_avatar_letters {
    font-size: 32px;
  }


  .chat_avatar_img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 50%;
  }
  
  
  </style>
  
  