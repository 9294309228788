<template>
    <li class="user-item-wrapper">
        <div class="user-item">
            <chat-logo variant='small' :image="user.logo" :text="user.username"/>
            <div> {{ user.username }} </div>
        </div>
        <div>
            
            <v-btn :disabled="hideAction" text :color="color" @click="$emit('action'); $emit('close'); $emit('hideBurger');">
                <slot name="button"></slot>
            </v-btn>
        </div>
    </li>
</template>


<script>
import ChatLogo from '../uikit/ChatLogo.vue';


export default {
    name: "UserSearch",
    data() {
        return { }
    },
    components: { ChatLogo },
    props: {
        color: {
            type: String,
            default:() => 'black',
        },
        user: {
            type: Object,
        },
        showButton: {
            type: Boolean,
            default:() => true,
        },
        hideAction: {
            type: Boolean,
            default:() => false,
        }
    },
};
</script>


<style scoped>

.danger .action_button:hover {
    background-color: rgb(156, 0, 0);
}

.user-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.user-item-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    animation: fadein 0.5s;
    list-style-type: none;
}

.action_button {
    height: 35px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

</style>
