import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import router from './router/index'
import store from './store'
import axios from 'axios';
import iconSvg from '@/components/uikit/iconSvg.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
Vue.config.productionTip = false
Vue.component('iconSvg', iconSvg);

Vue.use(VueRouter)


axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_HOST;
axios.interceptors.request.use(function(request) {
    // установка headers для каждого запроса
    request.headers.authorization = store.getters.Authorization;
    return request;
}, function(error) {
    return Promise.reject(error);
});

axios.interceptors.response.use(function(response) {
    return response;
}, function(error) {
    if (error.response.status === 401) {
        // если получили 401 ошибку то перекидывает на авторизацию

        store.dispatch('fetchLogout401');
        let path = location.pathname.substr(1);
        if (path !== 'login/') {
            router.push({ path: `/login/?next=${path}` });
        }
    }
    return Promise.reject(error);
});


new Vue({
    render: h => h(App),
    router,
    vuetify,
    store
}).$mount('#app')