<template>
  <div class="chat_header resize-m-header">

    <div class="chat_arrow" @click="$router.push('/')">
      <iconSvg name="arrow_left" />
    </div>
    <div @click="openChat" class="chat_info">
      <ChatLogo v-if="getChatCurrent" :image="getChatCurrent.logo" :text="getChatCurrent.name" />
      <div class=chat_name>
        {{ getChatCurrent.name }}
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChatLogo from './uikit/ChatLogo.vue';
export default {
  name: "ChatHeader",

  props: {
  },
  methods: {
    ...mapActions(['fetchCurrentChat', 'fetchRoomMessages']),
    openChat() {
      switch(this.getChatCurrent.chat_type) { 
        case 'public':
          return this.$emit('openGroupInfo');
        case 'private':
          return this.$emit('openUserInfo');
        default:
          console.log(this.getChatCurrent.chat_type);
      }
    }
  },
  created() {
    this.fetchCurrentChat(this.$route.params.id);
    this.fetchRoomMessages({chat:this.$route.params.id});
  },
  mounted() {
    this.firstLoad = true;
  },

  updated() {
  },
  computed: {
    ...mapGetters(['getChatCurrent']),
  },
  components: {
    ChatLogo,
  },
};
</script>


<style scoped lang="scss">
@import '@/styles/_variable.scss';

.chat_header {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  position: static;
  top: 0;
  background-color: #343c4b;
  height: $header_h;
  line-height: $header_h;
  width: 100%;
}

.chat_arrow {
  width: 100px;
  text-align: center;
  width: 70px;
  min-width: 70px;
  display: none;
}

.chat_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  padding-left: 30px;
  color: white;
  gap: 15px;
}

.chat_name {
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 768px) {
  .chat_arrow {
    display: block;
  }

  .chat_info {
    padding-left: 0px;
  }
}
</style>