import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import chat from './modules/chat'
import message from './modules/message'
import wsdriver from './modules/wsdriver'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        user,
        chat,
        message,
        wsdriver,
    }
})