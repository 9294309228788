<template>
    <modal-box title="Настройки чата" @close="$emit('close')">
        <v-card color="transparent">
            <v-tabs color="primary" right>
                <v-tab>Участники</v-tab>
                <v-tab>Редактирование</v-tab>

                <v-tab-item class="tab__content" :key="1">
                    <user-search :userCallback="addUser" :hiddenUsers="groupChat.users"></user-search>
                    <div v-for="(userAdded, index) in groupChat.users" :key="index">
                    <user-item :showButton="getUser.id !== userAdded.id" :user="userAdded" color="error"
                        @action="removeUser(index)">
                        <template #button> Удалить </template>
                    </user-item>
                    </div>
                </v-tab-item>

                <v-tab-item class="tab__content" :key="2">
                    <v-text-field variant="outlined"
                        v-model="groupChat.name"
                        label="Название группы"
                        required

                    ></v-text-field>

                    <v-btn @click="deleteGroupChat(chatId); $emit('close'); $router.push('/')" text color="error"> Удалить группу</v-btn>
                </v-tab-item>
        </v-tabs>
    </v-card>
    <div style="margin-bottom: 20px;" class="line"></div>
    <div class="buttons">
        <v-btn text @click="$emit('close')">Отмена</v-btn>
        <v-btn color="primary" @click="saveGroupChat()">Сохранить</v-btn>
        </div>
    </modal-box>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import UserSearch from './sidebar/UserSearch.vue';
import ModalBox from './uikit/ModalBox.vue';
import UserItem from "./uikit/UserItem.vue";

export default {
    name: "ChatUserSearch",
    data() {
        return {
            tab: null,
            search_username: '',
            groupChat: {
                users: [],
                name: '',
            },
        };
    },
    props: {
        chatId: Number,
        chat: Array,
    },
    components: {
        ModalBox,
        UserSearch,
        UserItem,
    },
    computed: {

        ...mapGetters(['getUserList', 'getUser']),
        userids() {
            return this.groupChat.users.map(e => {
                return e.id;
            })
        }
    },

    methods: {
        ...mapActions(['updateGroupChat', 'deleteGroupChat']),
        addUser(user) {
            this.groupChat.users.push(user);
        },
        saveGroupChat() {
            const data = {
                name: this.groupChat.name,
                users: this.groupChat.users.map(obj => { return obj.id })
            }
            this.updateGroupChat({ id: this.chatId, data });
            this.$emit('close');
        },
        removeUser(index) {
            this.groupChat.users.splice(index, 1);
        },
    },
    mounted() {
        this.groupChat = {...this.chat};
    },

};
</script>


<style scoped>
.buttons {
    position: absolute;
    padding: 20px;
    bottom: 0px;
    right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    background-color: #e8f0fe;
}

.buttons>button {
    width: 140px;
    height: 50px;
}

.line {
    border-bottom: 1px solid rgba(128, 128, 128, 0.432);
}


</style>

<style>
.theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent;
    margin-bottom: 20px;
}
.theme--light.v-tabs-items {
    background-color: transparent ;
}
.v-sheet.v-card:not(.v-sheet--outlined){
    box-shadow: unset;
}

</style>