<template>
  <div class="chat__side">

    <BurgerMenu
      @setRoom="$emit('setRoom', $event)"
      @showMessageList="$emit('showMessageList')"
    />

    <SelectChatList
      @setRoom="$emit('setRoom', $event)"
      @showMessageList="$emit('showMessageList')"
    />

  </div>
</template>

<script>

import BurgerMenu from "@/components/sidebar/BurgerMenu.vue";
import SelectChatList from "./SelectChatList.vue";
export default {
  name: "SideBar",
  components: {
    BurgerMenu,
    SelectChatList,
  },
};
</script>


<style scoped>

.chat__side {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  background-color: #343c4b;
  width: 40vw;
}

</style>
