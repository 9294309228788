<template>
  <div class="message" :class="isSelf ? `message__item__self` : `message__item`" v-if="message">
    <ChatLogo style="align-self: flex-end;" variant="small" :text="author" :image="userImage" />
    <message-text 
      @onOpenContext="openContextMenu" 
      
      style="margin-left: 30px; margin-right: 30px;" :author="author" :text="message.text" :date="message.timestamp"
      :isSelf="isSelf" />
      <v-card v-click-outside="closeContextMenu" v-if="showMessageMenu" class="mx-auto message_menu" max-width="300">
      <v-list>
        <v-list-item key=1 @click.stop="deleteCurrentMessage(message.id)">
          <v-list-item-title> Удалить</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-card>


  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChatLogo from './uikit/ChatLogo.vue';
import MessageText from "./uikit/MessageText.vue";
export default {
  name: "MessageItem",

  data() {
    return {
      selfMessage: false,
      showMessageMenu: false,

    }
  },
  methods: {
    ...mapActions(["websocketSend"]),
    openContextMenu() {
      this.showMessageMenu = true;
    },
    closeContextMenu() {
      this.showMessageMenu = false;
    },
    deleteCurrentMessage(id) {
      this.websocketSend({command: 'send_chat_delete_message', id:id, chat: this.chatId})
      this.closeContextMenu();
    },
  },
  components: {
    ChatLogo,
    MessageText,
  },
  computed: {
    userImage() {
      return this.message?.author?.logo;
    },
    author() {
      return this.message && this.message.author ? this.message.author.username : `Deleted`;
    },
    isSelf() {
      return this.message.author && this.message.author.id === this.getUser.id;
    },
    ...mapGetters(["getUser"]),

  },
  props: {
    chatId: Number,
    message: Object,
  },
};
</script>


<style scoped>
.message__item,
.message__item__self {
  display: flex;
  padding-top: 20px;
}

.message__item__self {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.message_menu {
  position: absolute;
  border-radius: 8px;
  top: 80%;
  right: 100px;
  z-index: 2003;

}

.message {
  position: relative;
}


.click_close_area {
  position: absolute;
  width: 100vw;
  height: 100dvh;
  opacity: 0.5;
  top: 0;
  z-index: 20;
  background-color: black;
}
</style>
