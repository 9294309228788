<template>
    <div class="time">{{ timestampPrint }} </div>
</template>

<script>
export default {
    name: "DateTime",
    props: {
        timestamp: {
            type: String,
            default: '',
        },
    },

    methods: {
        dateObj(date) {
            return {      
            hh: ("0" + date.getHours()).slice(-2),
            mm: ("0" + date.getMinutes()).slice(-2),
            DD: ("0" + date.getDate()).slice(-2),
            MM: ("0" + date.getMonth()).slice(-2),
            YYYY: date.getFullYear(),
        }
    }
    },
    computed: {
        timestampPrint() {
            const dateObj = this.dateObj(new Date(this.timestamp));
            const dateNowObj = this.dateObj(new Date());

            if (dateNowObj.DD === dateObj.DD &&
                dateNowObj.MM === dateObj.MM &&
                dateNowObj.YYYY === dateObj.YYYY) {
                return `${dateObj.hh}:${dateObj.mm}`;
            }
            return `${dateObj.DD}.${dateObj.MM}.${dateObj.YYYY}`
        },
    },
};
</script>


<style scoped>

.time {
    color: grey;
    font-size: 14px;
    overflow: hidden;
}

</style>
