import axios from 'axios';
export default ({
    state: {
        user: {},
    },
    getters: {
        getUser: (state) => state.user,

    },
    mutations: {
        setUser(state, data) {
            state.user = data;
        },
    },
    actions: {
        fetchUser(ctx) {
            if (ctx.getters.isLoggedIn === false) {
                return;
            }
            axios.get(`/api/auth/users/me/`)
                .then((response) => {
                    ctx.commit('setUser', response.data);
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        saveUserProfile(ctx, data) {
            let fd = new FormData()
            fd.append('first_name', data.first_name);
            fd.append('last_name', data.last_name);
            if (data.logo) {

                fd.append('logo', data.logo);
            }

            axios.patch(`/api/auth/profile/`, fd)
            .then((response) => {
                console.log(response);
            })
            .catch((err) => {
                console.log(err);
            });
        }

    },
})