<template>
  <div class="room_list_wrap" v-if="getChatList && getChatList.length">
    <div class="room_list" v-for="chat in getChatList" :key="chat.id">
      <div class="room" :class="activeChat(chat)" @click="goToRoom(chat)">
        <div class="chat_wrap">
          <ChatLogo :image="chat.logo" :text="chat.name" />
          <ChatInfo :chat="chat" />
        </div>
        <div class="message_meta">
          <date-time-short :timestamp="saveTimeStamp(chat)" />
          <new-message-icon/>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import ChatLogo from "../uikit/ChatLogo.vue";
import ChatInfo from "../sidebar/ChatInfo.vue";
import DateTimeShort from "../uikit/DateTimeShort.vue";
import NewMessageIcon from '../uikit/NewMessageIcon.vue';
export default {
  name: "SelectChatList",
  data() {
    return {
      chatId: "",
    };
  },
  props: {
  },
  components: {
    ChatLogo,
    ChatInfo,
    DateTimeShort,
    NewMessageIcon,
  },
  computed: {
    ...mapGetters(["getChatList"]),
  },
  methods: {
    saveTimeStamp: (chat) => chat?.last_msg?.timestamp || '',
    ...mapActions(["fetchChatList", "fetchRoomMessages", "fetchCurrentChat"]),
    ...mapMutations(["setChatCurrent"]),
    activeChat(chat) {
      if (this.chatId === chat.id) {
        return "active";
      }
      return "";
    },
    goToRoom(chat) {
      this.fetchCurrentChat(chat.id);
      this.fetchRoomMessages({ chat: chat.id });
      this.$emit('setRoom', chat.id);
      this.chatId = chat.id;
      this.setChatCurrent(chat);
      this.$emit('showMessageList');
    },
  },

  mounted() { },
  created() {
    this.fetchChatList();
  },
};
</script>


<style scoped>
.room {
  padding: 8px 20px;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
}

.room:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.active,
.active:hover {
  background-color: rgba(0, 0, 0, 0.26);
}

.chat_wrap {
  display: flex;
  justify-content: flex-start;
  height: 100%;
  align-items: center;
}


.new_message_icon {
  width: 100%;
  height: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.new_message_icon__circle {
  width: 12px;
  min-width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(100, 100, 255, 0.794);
}

.message_meta {
  width: 40px;
  min-width: 40px;
  padding: 5px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding: 10px;
  justify-content: space-between;
}
</style>

