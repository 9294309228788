<template>
  <modal-box v-if="jwt" title="Вы авторизованы" :showClose=false>
    <form class="input_form" v-on:submit.prevent="fetchLogout()">
      <router-link to="/"><button class="link">На главную</button></router-link>
      <v-btn color="error" type="submit">Выйти</v-btn>
      <div class="error-msg">{{ errorMsg }}</div>
    </form>
  </modal-box>

  <modal-box v-else title="Вход" :showClose=false>
    <form class="input_form" v-on:submit.prevent="fetchLogin(authForm)">
      <v-text-field variant="outlined" v-model="authForm.username" label="username" required></v-text-field>
      <v-text-field variant="outlined" type="password" v-model="authForm.password" label="password"
        required></v-text-field>
      <v-btn color="primary" type="submit">Войти</v-btn>
      <v-btn href="/registration/">Создать новую учетную запись</v-btn>
      <div class="error-msg">{{ errorMsg }}</div>
    </form>
  </modal-box>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ModalBox from '../components/uikit/ModalBox.vue';
export default {
  name: "LoginPage",
  data() {

    return {
      authForm: {
        username: "",
        password: "",
      },
    };
  },
  components: {
    ModalBox,
  },
  props: {},
  computed: {
    ...mapGetters(["jwt", "errorMsg"]),
  },
  methods: {
    ...mapActions(["fetchLogin", "fetchLogout"]),
  },
  mounted() {
  },
};
</script>



<style  scoped>
.simple_link {
  text-align: center;
}
</style>
