<template>
    <modal-box title="Настройки чата" @close="$emit('close')">
        <div class="user__info">
            <ChatLogo variant="big" :image="user.logo"/>
            <h3> {{ user.name }}  </h3>
        </div>
        
        <v-btn @click="deleteDirectChat(chatId); $emit('close'); $router.push('/')" text color="error"> Удалить контакт</v-btn>
    </modal-box>
</template>


<script>
import { mapActions } from "vuex";
import ModalBox from './uikit/ModalBox.vue';
import ChatLogo from "@/components/uikit/ChatLogo.vue";

export default {
    name: "ChatUserInfo",
    data() {
        return {
            
        };
    },
    props: {
        user: Object,
        chatId: Number,
    },
    components: {
        ModalBox,
        ChatLogo,

    },

    methods: {
    ...mapActions(['deleteDirectChat']),
    },
    mounted() {
        this.usersInChat = this.chatUsers;

    },

};
</script>


<style scoped>
.buttons {
    position: absolute;
    padding: 20px;
    bottom: 0px;
    right: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    background-color: #e8f0fe;
}

.buttons>button {
    width: 140px;
    height: 50px;
}

.line {
    border-bottom: 1px solid rgba(128, 128, 128, 0.432);
}


</style>

<style>
.theme--light.v-tabs > .v-tabs-bar {
    background-color: transparent;
    margin-bottom: 20px;
}
.theme--light.v-tabs-items {
    background-color: transparent ;
}
.v-sheet.v-card:not(.v-sheet--outlined){
    box-shadow: unset;
}

.user__info {
    display: flex;
    align-items: center;
    gap: 20px;

}

</style>