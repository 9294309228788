<template>
  <div class="time">  {{ timestampPrint }}  </div>
</template>


<script>
export default {
  name: "DateTimeShort",
  props: {
    timestamp: {
      type: String,
      default: '',
    },
  },
  computed: {
    timestampPrint() {
      if (this.timestamp) {
        const date = new Date(this.timestamp);
        const hh = ("0" + date.getHours()).slice(-2);
        const mm = ("0" + date.getMinutes()).slice(-2);
        return `${hh}:${mm}`;
      }
      return '';
    },
  },
  methods: {},
};
</script>


<style scoped>
.time {
  font-size: 14px;
  overflow: hidden;
  color: rgb(189, 189, 189);
}

</style>

