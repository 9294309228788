<template>
<div>
    <div @click="showBurger" class="open">
      <iconSvg name="burger_menu" class="icon32" />
    </div>

    <div v-if="isShowBurger" @click="hideBurger" class="click_close_area"> </div>
    <div class="burger_menu" :class="isShowBurger ? 'activemenu' : ''">
      <v-btn
              icon
              color="white"
              @click="hideBurger"
              class="close"
            >
              <iconSvg name="iconclose" />
            </v-btn>
        
        <div class="menu_list">
          <GroupChatMenu @hideBurger="hideBurger">
            <iconSvg class="icon20" name="group2"/>
            Создать группу
          </GroupChatMenu>
          
          <UserProfile @hideBurger="hideBurger"> 
            <iconSvg class="icon20" name="profile"/>
            Мой профиль 
          </UserProfile>
          
          <UserSearchMenu @hideBurger="hideBurger"> 
            <iconSvg class="icon20" name="search"/>
            Контакты
          </UserSearchMenu>
          
          <SideBarButton @click="fetchLogout"> 
            <iconSvg class="icon20" name="exit"/>
              Выход 
            </SideBarButton>
            
        </div>
    </div> 


</div>
</template>



<script>
import { mapActions } from "vuex";
import SideBarButton from "./SideBarButton.vue";
import GroupChatMenu from "./GroupChatMenu.vue";
import UserSearchMenu from "./UserSearchMenu.vue";
import UserProfile from "./UserProfile.vue";
export default {
  name: "BurgerMenu",
  data() {
      return {
          isShowBurger: false,
      };
  },
  components: {
        GroupChatMenu,
        UserSearchMenu,
        SideBarButton,
        UserProfile,
  },
  methods: {
    ...mapActions(["fetchLogout"]),
    showBurger() {
      this.isShowBurger = true;
      this.addEscClose();
    },
    hideBurger() {
      this.isShowBurger = false;
      this.rmEscClose();
    },
    escClose(e) {
        if ((e.key == 'Escape' || e.key == 'Esc' || e.keyCode == 27)) {
          this.hideBurger();
        }
    },
    addEscClose() {
      document.addEventListener('keydown', this.escClose, true);
    },
    rmEscClose() {
      document.removeEventListener('keydown', this.escClose, true);
    },
  },



};

</script>



<style scoped lang="scss">

@import '@/styles/_variable.scss';

.open {
  position: sticky;
  top: 0;
  left: 0;
  padding: 15px;
  width: 100%;
  background-color: #343c4b;
  cursor: pointer;
  color: rgb(255, 255, 255);
  transition: 0.2s;
  height: $header_h;
}

.open:hover {
  color: rgba(255, 255, 255, 0.719);
}

.close {
  width: 45px;
  height: 45px;
  padding: 15px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: 0.2s all;
  cursor: pointer;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close:hover {
  transform: scale(1.2);
  
}

.click_close_area {
  position: absolute;
  width: 100vw;
  height: 100dvh;
  opacity: 0.5;
  top: 0;
  background-color: black;
}

.burger_menu {
  position: absolute;
  background-color: #343c4b;
  width: max-content;
  transition: 0.4s;
  top:0;
  left: -40vw;
  height: 100%;
  z-index: 100;
}

.menu_list {
  display: flex;
  margin: 70px 0;
  padding: 0 40px;
  flex-direction: column;
  gap:16px;
}




.activemenu {
  transform: translate(40vw);
}

@media (max-width: 576px) {
  .burger_menu {
    left: -100vw;
    width: 100vw;
  }
  .activemenu {
    transform: translate(100vw);
  }
}

.icon20 {
  width: 20px;
  height: 20px;
  fill: currentColor;
  margin-right: 10px;
}

.icon32 {
  width: 32px;
  height: 32px;
  fill: currentColor;
}
</style>
