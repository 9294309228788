<template>
    <div class="new_message_icon">
        <div class="new_message_icon__circle"></div>
    </div>
</template>


<script>
export default {
    name: "NewMessageIcon",
    data() {
        return {}
    },
    props: {},
    components: {},
    computed: {},
    methods: {},
    mounted() { },
    created() { },
};
</script>


<style scoped>
.new_message_icon {
    width: 100%;
    height: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
}

.new_message_icon__circle {
    width: 12px;
    min-width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(100, 100, 255, 0.794);
}
</style>

